import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import userService from "@app/services/users/userService";

import {
  ButtonLinkIcon,
  PrimaryButton,
  PrimaryButtonOutlined,
} from "@components/Button";
import Card from "@components/Card";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";
import { InputDropdown } from "@components/Input";
import {
  Text,
  TextMediumWeight,
  TextSemiBoldWeight,
  TextUpperCase,
} from "@components/Text";

const AssigneeCard = ({ selectedAssignee, onUpdateAssignee, onError }) => {
  const { messages } = useIntl();

  const [isAssigneeDialogVisible, setIsAssigneeDialogVisible] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [assigneeValue, setAssigneeValue] = useState(selectedAssignee);

  useEffect(() => {
    const fetchAssignees = async () => {
      try {
        const { data: { data: assigneesData = [] } = {} } = await userService(
          "filter[type]=admin&per_page=100"
        );
        const filteredAssignees = assigneesData.filter(assignee =>
          assignee.name?.trim()
        );

        setAssignees(filteredAssignees);
      } catch (error) {
        onError?.();
      }
    };

    fetchAssignees();
  }, [onError]);

  const handleOpenAssigneeDialog = () => {
    setIsAssigneeDialogVisible(true);
  };

  const handleCloseAssigneeDialog = () => {
    setIsAssigneeDialogVisible(false);
  };

  const handleChangeAssignee = event => {
    const { value } = event;

    setAssigneeValue(value);
  };

  const handleUpdateAssignee = () => {
    const event = {
      value: assigneeValue,
    };

    onUpdateAssignee(event);
    handleCloseAssigneeDialog();
  };

  const renderSelectedAssignee = () => (
    <Div
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gridGap={1}
    >
      {selectedAssignee ? (
        <>
          <Icon name="assignee" fontSize="20px" color="var(--turquoise)" />

          <Text>{selectedAssignee?.name}</Text>
        </>
      ) : (
        <TextUpperCase color="var(--red) !important">
          {messages.not_assigned}
        </TextUpperCase>
      )}
    </Div>
  );

  return (
    <Div>
      {isAssigneeDialogVisible && (
        <Dialog
          width={[1, 1, "25%", "25%"]}
          visible="displayBasic"
          draggable={false}
          onHide={handleCloseAssigneeDialog}
          header={<H3 textAlign="center" textTransform="none">{messages.label_assignee}</H3>}
        >
          <InputDropdown
            onChange={handleChangeAssignee}
            label={`${messages.label_assignee}:`}
            placeholder={messages.watchlist_label_choose}
            value={assigneeValue}
            options={assignees}
            optionLabel="name"
          />
          <Div
            mt={3}
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            justifyContent="center"
            gridGap={3}
          >
            <PrimaryButton
              width={[1, 1, "150px", "150px"]}
              height="40px"
              label={messages.label_save}
              onClick={handleUpdateAssignee}
            />

            <PrimaryButtonOutlined
              width={[1, 1, "150px", "150px"]}
              height="40px"
              label={messages.label_cancel}
              onClick={handleCloseAssigneeDialog}
            />
          </Div>
        </Dialog>
      )}

      <Card mb={3} p={3} borderRadius="20px" width={1}>
        <Div display="flex" justifyContent="space-between" alignItems="center">
          <Div display="flex" alignItems="center" gridGap={2}>
            <TextSemiBoldWeight>{`${messages.label_assignee}:`}</TextSemiBoldWeight>
            {renderSelectedAssignee()}
          </Div>

          <ButtonLinkIcon
            px={0}
            label={<TextMediumWeight>{messages.label_edit}</TextMediumWeight>}
            onClick={handleOpenAssigneeDialog}
            icon={
              <Icon
                name="pen"
                mr={2}
                color="var(--turquoise)"
                fontSize="var(--fs-icon-s)"
              />
            }
          />
        </Div>
      </Card>
    </Div>
  );
};

AssigneeCard.propTypes = {
  selectedAssignee: PropTypes.object,
  onUpdateAssignee: PropTypes.func,
  onError: PropTypes.func,
};

export default AssigneeCard;
