import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import createCasesReport from "@app/services/cases/createCasesReport";
import deleteCasesReportById from "@app/services/cases/deleteCasesReportById";
import getCaseDetailsById from "@app/services/cases/getCaseDetailsById";
import updateCaseDetailsById from "@app/services/cases/updateCaseDetailsById";

import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import ProgressSpinner from "@components/ProgressSpinner";
import PromptDialog from "@components/PromptDialog";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { CASES_DATA_STATUS_TYPE, SURVEY_MODE_TYPE } from "@utils/enum";

import {
  ReportFormComponent,
  ReportPreviewDialog,
  ReportStateMessageComponent,
} from "./components";
import {
  QUESTION_DIALOG_CONTENT_TYPE,
  QUESTION_DIALOG_TYPE,
} from "./constants";

const ReportTab = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { case_id } = useParams();
  const { showErrorToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [questionDialogContentType, setQuestionDialogContentType] = useState(
    QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
  );
  const [caseDetails, setCaseDetails] = useState({});
  const [isFullScreenDialogVisible, setIsFullScreenDialogVisible] =
    useState(false);
  const [isPreviewDialogVisible, setIsPreviewDialogVisible] = useState(false);

  const surveyRef = useRef(null);

  const hasWriteReportAnalysisPermission = hasAllPermissions([
    AdminPermissions.AdminWriteReportAnalysis,
  ]);

  const {
    report_data: reportData,
    report_config: reportConfig,
    process_type: processType,
    data_sources_status: sourceDataStatus,
    report_id: reportId,
  } = caseDetails;

  const isReportCreated = reportId !== null;
  const isSurveyEditable =
    sourceDataStatus === CASES_DATA_STATUS_TYPE.COMPLETED &&
    !isReportCreated &&
    hasWriteReportAnalysisPermission;
  const shouldShowMessage =
    !isLoading &&
    (isReportCreated || sourceDataStatus !== CASES_DATA_STATUS_TYPE.COMPLETED);
  const surveyMode = isSurveyEditable
    ? SURVEY_MODE_TYPE.EDIT
    : SURVEY_MODE_TYPE.DISPLAY;

  useEffect(() => {
    const fetchCaseDetails = async () => {
      try {
        setIsLoading(true);

        const { data: caseData = {} } = await getCaseDetailsById(case_id);

        setCaseDetails(caseData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCaseDetails();
  }, [case_id, messages, showErrorToast]);

  const handleUpdateCase = useCallback(
    async data => {
      try {
        setIsLoading(true);

        const payload = {
          report_data: data,
        };

        const { data: caseData = {} } = await updateCaseDetailsById(
          case_id,
          payload
        );

        setCaseDetails(caseData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [case_id, messages, showErrorToast]
  );

  const handleCompleteSurvey = useCallback(
    survey => {
      handleUpdateCase(survey);
    },
    [handleUpdateCase]
  );

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    handleUpdateCase(data);
  };

  const handleCreateReport = () => {
    if (!surveyRef.current) {
      return;
    }

    const survey = surveyRef.current;
    const validationResult = survey.validate();

    if (!validationResult || survey.hasErrors()) {
      return;
    }

    const createType =
      QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.CREATE];
    createType.message = (
      <FormattedMessage
        id="message_create_report"
        defaultMessage="Are you sure you want to create a report for {productName}?"
        values={{
          productName: caseDetails?.product?.name,
        }}
      />
    );
    createType.onConfirm = handleConfirmCreateReport;

    setQuestionDialogContentType(createType);

    handleSave();
  };

  const handleConfirmCreateReport = () => {
    createReport();
  };

  const handleReCreateReport = () => {
    const reCreateType =
      QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.RE_CREATE];
    reCreateType.onConfirm = handleConfirmReCreateReport;

    setQuestionDialogContentType(reCreateType);
  };

  const createReport = async () => {
    try {
      setIsLoading(true);

      const { id: generatedReportId = "" } = await createCasesReport({
        case_id: case_id,
      });

      setCaseDetails({ ...caseDetails, report_id: generatedReportId });
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
      setQuestionDialogContentType(
        QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
      );
    }
  };

  const recreateReport = async () => {
    try {
      setIsLoading(true);

      await deleteCasesReportById(reportId);

      setCaseDetails({ ...caseDetails, report_id: null });
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
      setQuestionDialogContentType(
        QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
      );
    }
  };

  const handleConfirmReCreateReport = () => {
    recreateReport();
  };

  const handleCloseQuestionDialog = () => {
    setQuestionDialogContentType(
      QUESTION_DIALOG_CONTENT_TYPE[QUESTION_DIALOG_TYPE.NONE]
    );
  };

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const toggleFullScreenVisibility = () => {
    setIsFullScreenDialogVisible(prevValue => !prevValue);
  };

  const handleOpenPreviewDialog = () => {
    handleSave();
    setIsPreviewDialogVisible(true);
  };

  const handleClosePreviewDialog = () => {
    setIsPreviewDialogVisible(false);
  };

  const handleUpdatePreview = ({ name, value }) => {
    const updatedData = { ...reportData, [name]: value };

    setCaseDetails(prevValue => ({
      ...prevValue,
      report_data: updatedData,
    }));
  };

  const renderReportForm = () => (
    <ReportFormComponent
      isLoading={isLoading}
      isReportCreated={isReportCreated}
      isSurveyEditable={isSurveyEditable}
      surveyMode={surveyMode}
      reportData={reportData}
      reportConfig={reportConfig}
      onToggleFullScreenMode={toggleFullScreenVisibility}
      onComplete={handleCompleteSurvey}
      onRefLoaded={handleLoadSurveyRef}
      onCreateReport={handleCreateReport}
      onSave={handleSave}
      onOpenPreviewDialog={handleOpenPreviewDialog}
      onUpdatePreview={handleUpdatePreview}
    />
  );

  const renderFullScreenDialog = () => {
    return (
      <FullSizeDialog
        fullWidth
        title={`${messages.label_case} #${case_id}: ${messages.label_report}`}
        onClose={toggleFullScreenVisibility}
      >
        <Div className="full-screen-preview">{renderReportForm()}</Div>
      </FullSizeDialog>
    );
  };

  return (
    <Div className="report-config">
      {isLoading && <ProgressSpinner />}

      {questionDialogContentType && (
        <PromptDialog
          title={questionDialogContentType?.title}
          message={questionDialogContentType?.message}
          onConfirm={questionDialogContentType?.onConfirm}
          onCancel={handleCloseQuestionDialog}
          confirmLabel={messages.label_create}
        />
      )}

      {shouldShowMessage && (
        <ReportStateMessageComponent
          isReportCreated={isReportCreated}
          reportId={reportId}
          processType={processType}
          sourceDataStatus={sourceDataStatus}
          onRecreateReport={handleReCreateReport}
        />
      )}

      {isFullScreenDialogVisible && renderFullScreenDialog()}

      {isPreviewDialogVisible && (
        <ReportPreviewDialog
          reportConfig={JSON.parse(reportConfig)}
          reportData={reportData}
          onClose={handleClosePreviewDialog}
        />
      )}

      {renderReportForm()}
    </Div>
  );
};

export default ReportTab;
